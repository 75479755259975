/* eslint-disable */
import { Fragment, useState, useEffect } from "react";
import styles from "./css/Request.module.css";
import * as XLSX from "xlsx";
import { writeFile } from "xlsx";
import axios from "../common/wrapper/axios";
import type { RequestData, CheckedData, FileNameList, VendorIdList } from "../data/requestList";
import { initial } from "lodash";
import { saveAs } from "file-saver";
import { UserEntity } from "../features/user/userApi";
import useLocalStorageToken from "../common/hooks/useLocalStorageToken";
import $ from "jquery";
// 의뢰 업로드 탭
function Request({ user }: any) {
  var token = "bearer " + sessionStorage.getItem("auth");

  let userId = 0;
  let userInstitution = "";
  let userAuthority = "";

  if (user != "undefined" && user != null) {
    userId = user.id;
    userInstitution = user.institution_name;
    userAuthority = user.authority;
  }
  //tab
  const [tab1, setTab1] = useState(0);

  //tab_active
  const [active, setActive] = useState("0px");

  const tabActiveFunc = () => {
    setTab1(0);
    setActive("0px");
  };
  const tabUnActiveFunc = () => {
    setTab1(1);
    setActive("56px");
  };

  //표준양식 엑셀다운로드
  const exportExcelForm = () => {
    const excelTableHead = [["*의사 명", "*채취일", "*검체 타입", "*검사 코드", "*검사 명", "*수진자 번호", "*수진자 명", "*성별", "*생년월일", "메모", "국적"]];
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);

    ws["!cols"] = [{ width: 9 }, { width: 9 }, { width: 9.5 }, { width: 9.5 }, { width: 10 }, { width: 12 }, { width: 12 }, { width: 9 }, { width: 11 }, { width: 9 }, { width: 9 }];

    XLSX.utils.sheet_add_aoa(ws, excelTableHead, { origin: "A1" });

    XLSX.utils.sheet_add_json(ws, [], { origin: "A2", skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, "gxForm");
    writeFile(wb, "hancomGX" + ".xlsx");
  };

  {
    /* 검사 의뢰 업로드 */
  }
  const Upload = () => {
    //console.log(userId);

    //excelFile 담기
    const [items, setItems] = useState("") as any;
    const [fileName, setFileName] = useState("");

    //excelFile 업로드
    const uploadExcelFile = () => {
      if (items.length == 0) {
        alert("선택한 파일이 없습니다.");
        //console.log(items.length);
      } else {
        alert("파일을 전송하시겠습니까?");

        const formData = new FormData();
        formData.append("file", items);
        axios
          .post(`/request/upload`, formData, { headers: { Authorization: token } })
          //성공시 then 실행
          .then(function (response) {
            //console.log(response);
            alert("전송이 완료되었습니다.");
            setItems("");
            setFileName("");
          })
          //실패 시 catch 실행
          .catch(function (error) {
            //console.log(error);
            alert("전송에 실패하였습니다.");
            setItems("");
            setFileName("");
          });
      }
    };
    return (
      <>
        <div className={styles.request_whiteBox}>
          <div className={styles.send}>
            <h4>의뢰파일 전송</h4>
            <div className={styles.box1}>
              <label htmlFor="UploadFile">파일선택</label>
              {/* ▼여기에 전송할 파일명이 떠야함. 일단은 placeholder로 넣어놓았음 */}
              <input type="text" className={styles.file_label} id="fileName" placeholder="전송할 파일을 첨부해 주세요." value={fileName} readOnly></input>
              <input
                type="file"
                id="UploadFile"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e: any) => {
                  const excelFile = e.target.files[0];
                  if (excelFile != null && excelFile.name != undefined) {
                    setFileName(excelFile.name);
                    setItems(excelFile);
                  } else {
                    setItems("");
                    setFileName("");
                  }
                }}
              ></input>
              {/* ▼전송하기 버튼 기능 넣어야함 */}
              <button type="button" onClick={uploadExcelFile}>
                전송하기
              </button>
            </div>
            <div className={styles.box2}>
              <p>신규 검사를 의뢰할 경우 파일을 전송해주세요.</p>
              <ul>
                <li>
                  <b>파일전송을 여러 번 하여 검체를 의뢰할 수 있습니다.</b>
                </li>
                <li>전송할 파일을 선택해 주세요, 단 .xls, .xlsx등 엑셀 파일만 가능합니다.</li>
                <li>
                  전송 파일명은 <b>영문 또는 숫자명</b>으로 작성하는 것을 권유합니다.
                  <br />
                  (전송 파일명은 병원측에서 관리하시기 편한 파일명으로 가능합니다)
                </li>
              </ul>
            </div>
          </div>

          <div className={styles.checklist}>
            <p>▶ 전송파일 오류 발생시 우선 다음 사항을 확인하여 주세요.</p>
            <ul>
              <li>
                전송되는 파일을 엑셀통합문서 표준 포맷으로 작성하여 주세요.
                <br />- 포맷을 변경하고 싶을 때에는 해당 영업담당자를 통해 요청바랍니다.
              </li>
              <li>검체 번호, 검사 코드가 중복된 접수 건이 있는지 확인하여 주세요.</li>
              <li>업로드 되는 파일 이름을 영문으로 변경하여 주세요. </li>
              <li>암호가 걸린 파일은 업로드가 되지 않습니다.</li>
            </ul>
            <p>▶ 파일 전송에 오류가 발생할 경우 다음과 같이 조치하여 주시기 바랍니다.</p>
            <ul>
              <li>오류가 발생한 엑셀 파일을 gxsupport@hancomcl.com로 보내주시기 바랍니다.</li>
              <li>발송하시는 메일에 병원 명, 병원 아이디, 담당자명, 연락처를 꼭 기재하여 주세요.</li>
              <li>메일을 발송하신 후 1800-5270 으로 연락을 남겨주세요.</li>
            </ul>

            <button type="button" onClick={exportExcelForm}>
              의뢰 업로드 표준 양식
            </button>
          </div>
        </div>
      </>
    );
  };

  {
    /* 의뢰파일 조회 */
  }
  const Check = () => {
    const [period, setPeriod] = useState("");

    const [hospi, setHospi] = useState(false);

    const intialValues = { startDate: "", endDate: "", vendorId: "" as any, size: 9999, page: 0 };

    const [sDate, setSdate] = useState("");
    const [eDate, setEdate] = useState("");
    const [formValues, setFormValues] = useState(intialValues);
    const [currValue, setCurrValue] = useState<number>();
    //거래처 리스트
    const [vendor, setVendor] = useState<VendorIdList[]>([]);

    const VendorNameBox = () => {
      axios
        .get(`vendor?searchText=&size=9999&page=0`, {
          headers: { Authorization: token },
        })
        //성공시 then 실행
        .then(function (response) {
          setVendor(response.data.content);
          //console.log(response.data.content);
        })
        //실패 시 catch 실행
        .catch(function () {
          //console.log(error);
        });
    };

    //검색조건 날짜 디폴트(오늘)
    useEffect(() => {
      let today: any = new Date();
      today = today.toISOString().slice(0, 10);
      setSdate(today);
      setEdate(today);
      intialValues.startDate = today;
      intialValues.endDate = today;
      intialValues.vendorId = null;
      if (user.id === 1) {
        VendorNameBox();
      }
    }, []);

    useEffect(() => {
      //console.log("거래처리스트: " + JSON.stringify(vendor));
    }, [vendor]);

    //검색조건 기간
    const handlePeriod = (e: any) => {
      const { value } = e.target;
      setPeriod(value);

      let currdate = new Date(eDate);
      if (value === "threeday") {
        let threeDaysAgo: any = new Date(currdate.getTime() - 3 * 24 * 60 * 60 * 1000);
        threeDaysAgo = threeDaysAgo.toISOString().slice(0, 10);
        setSdate(threeDaysAgo);
      }
      if (value === "oneweek") {
        let oneWeekAgo: any = new Date(currdate.getTime() - 7 * 24 * 60 * 60 * 1000);
        oneWeekAgo = oneWeekAgo.toISOString().slice(0, 10);
        setSdate(oneWeekAgo);
      }
      if (value === "onemonth") {
        let oneMonthAgo: any = new Date(currdate.getFullYear(), currdate.getMonth() - 1, currdate.getDate());
        oneMonthAgo = oneMonthAgo.toISOString().slice(0, 10);
        setSdate(oneMonthAgo);
      }
      if (value === "threemonth") {
        let threeMonthsAgo: any = new Date(currdate.getFullYear(), currdate.getMonth() - 3, currdate.getDate());
        threeMonthsAgo = threeMonthsAgo.toISOString().slice(0, 10);
        setSdate(threeMonthsAgo);
      }
    };

    useEffect(() => {
      // body 클릭 시, isVisible 상태를 false로 변경
      const handleBodyClick = () => {
        setHospi(false);
      };
      document.body.addEventListener("click", handleBodyClick);

      return () => {
        document.body.removeEventListener("click", handleBodyClick);
      };
    }, []);

    // .hospital 가 없는 요소를 클릭했을 때, isVisible 상태를 true로 변경
    // const handleNonTargetClick = (event: React.MouseEvent<HTMLDivElement>) => {
    //   const target = event.target as HTMLDivElement;
    //   if (!target.classList.contains("hospital")) {
    //     setHospi(false);
    //     console.log("Asdfasdfasdf")
    //   }
    // };

    //의뢰목록 조회
    const [resData, setResData] = useState<RequestData>([] as any);

    const getRequestList = async () => {
      formValues.startDate = sDate;
      formValues.endDate = eDate;
      formValues.size = intialValues.size;
      formValues.page = intialValues.page;
      formValues.vendorId = currValue;
      //console.log("currValue: " + currValue);
      let url = "";
      if (userAuthority === "ADMIN") {
        url = `request?vendorId=${formValues.vendorId}&startDate=${formValues.startDate}&endDate=${formValues.endDate}&size=${formValues.size}&page=${formValues.page}`;
        //console.log(url);
      } else {
        url = `request?vendorId=&startDate=${formValues.startDate}&endDate=${formValues.endDate}&size=${formValues.size}&page=${formValues.page}`;
        //console.log(url);
      }

      axios
        .get(url, {
          headers: { Authorization: token },
        })
        //성공시 then 실행
        .then(function (response) {
          if (response.data.content.length === 0) {
            setResData([] as any);
            alert("선택하신 기간내 다운로드할 의뢰서가 없습니다.");
          } else {
            setResData(response.data);
            //console.log(response.data);
          }
        })
        //실패 시 catch 실행
        .catch(function () {
          //console.log(error);
        });
    };

    //날짜변환 함수
    const moment = (date: any) => {
      const createDate = date.slice(0, 10);
      const createTime = date.slice(11, 19);
      //console.log(createDate + " " + createTime);
      return createDate + " " + createTime;
    };

    //체크박스
    const [chkList, setChkList] = useState<CheckedData[]>([]);

    //개별선택
    const checkHandler = (checked: boolean, item: any) => {
      //console.log(checked, item);
      if (checked) {
        setChkList((prev): any => [...prev, item]);
      } else if (!checked) {
        setChkList(chkList.filter((el) => el !== item));
      }
    };

    //전체선택
    const AllCheckHandler = (checked: boolean) => {
      if (checked) {
        const checkedArr = [] as CheckedData[];
        resData.content.forEach((el: any) => checkedArr.push(el));
        setChkList(checkedArr);
      } else if (!checked) {
        setChkList([]);
      }
    };

    const [fName, setFname] = useState<FileNameList[]>([]);
    const checkedFileName = async () => {
      const filenameLst: any = chkList.map((it: FileNameList) => {
        return {
          file_name: it.file_name,
          real_file_name: it.real_file_name,
        };
      });
      setFname(filenameLst);
    };

    useEffect(() => {
      checkedFileName();
    }, [chkList]);

    //excelFile읽기
    const [reqitems, setReqItems] = useState("") as any;
    //const [headers, setHeaders] = useState("") as any;
    const downloadExcelFile = (fname: any) => {
      //console.log(JSON.stringify(fname));
      if (chkList.length === 0) {
        alert("다운로드할 항목을 선택해 주세요.");
      } else {
        alert("선택한 파일을 다운로드 하시겠습니까?");
        const result = Promise.all(
          fname.map(async (param: any) => {
            return await axios
              .get(`request/download?realFileName=${param.real_file_name}&fileName=${param.file_name}`, {
                headers: { Authorization: token },
                responseType: "blob",
              })
              //성공시 then 실행
              .then(function (response) {
                setReqItems(response.data);
                //setHeaders(response.headers);
                //console.log(response.headers["content-disposition"].split("filename=")[1].split(".")[0]);
                const blob = new Blob([response.data], { type: response.headers["content-type"] });

                saveAs(blob, `${param.file_name}`);
              })
              //실패 시 catch 실행
              .catch(function () {
                //console.log(error);
              });
          })
        );
        return result;
      }
    };

    useEffect(() => {
      //readExcelFile(reqitems);
      //console.log(reqitems);
    }, [reqitems]);

    // const readExcelFile = (file: any) => {
    //   console.log(file);
    //   const fileReader = new FileReader();
    //   fileReader.onload = () => {
    //     const fileData = fileReader.result;
    //     const wb = XLSX.read(fileData, { type: "file" });
    //     const sheetNameList = wb.SheetNames;
    //     const firstSheetName = sheetNameList[0];
    //     const firstSheet = wb.Sheets[firstSheetName];
    //     console.log(JSON.stringify(firstSheet));
    //     const rowObj = XLSX.utils.sheet_to_json(firstSheet);
    //     console.log(JSON.stringify(rowObj));
    //     setItems(rowObj);
    //   };
    //   fileReader.readAsBinaryString(file);
    //   for (let i = 0; i < fName.length; i++) {
    //     let blob = new Blob(file, {
    //       type: file.headers["Content-Type"],
    //     });
    //     const fileName = headers["Content-Disposition"].toString().split("filename=")[1].split(".")[0];
    //     saveAs(blob, `${fileName}.xlsx`);
    //   }
    // };

    //거래처 검색 이벤트
    const [inputvendor, setInputVendor] = useState("");

    const VendorSelect = () => {
      const handleSelectValue = (e: any) => {
        const innerTxt: number = e.institution_id;
        //console.log(e.vendor_name);
        //console.log(e.institution_id);
        setCurrValue(innerTxt);
        formValues.vendorId = innerTxt;

        $("#vendorId").val(e.vendor_name);
      };

      const filterVendor = vendor.filter((vendors: any) => {
        return vendors.vendor_name.includes(inputvendor);
      });

      return (
        <ul className={styles.hospital}>
          {filterVendor.map((it: any) => (
            <li key={it.institution_id} onClick={() => handleSelectValue(it)}>
              <p>
                {it.vendor_name}
                <span>{it.institution_id}</span>
              </p>
            </li>
          ))}
        </ul>
      );
    };

    useEffect(() => {
      setInputVendor(inputvendor);
      setCurrValue([] as unknown as number);
    }, [inputvendor]);

    return (
      <>
        <div className={styles.chk_Box_wrap}>
          <div className={styles.chk_whiteBox}>
            <div>
              <div className={styles.hospi_name}>
                <h4>거래처명</h4>
                {userAuthority === "ADMIN" ? (
                  <input
                    type="text"
                    id="vendorId"
                    name="vendorId"
                    placeholder="거래처명을 검색하세요."
                    onChange={(e: any) => {
                      setHospi(true);
                      setInputVendor(e.target.value);
                    }}
                  />
                ) : (
                  <input
                    type="text"
                    id="vendorId"
                    name="vendorId"
                    value={userInstitution}
                    readOnly
                    // onClick={handleNonTargetClick}
                  />
                )}
                {hospi == true ? <VendorSelect /> : null}
              </div>
              <div className={styles.select_period}>
                <ul>
                  <li>
                    <h4>시작일</h4>
                    <input type="date" id="startDate" name="startDate" onChange={(e: any) => setSdate(e.target.value)} value={sDate} />
                  </li>
                  <li>
                    <h4>종료일</h4>
                    <input type="date" id="endDate" name="endDate" onChange={(e: any) => setEdate(e.target.value)} value={eDate} />
                  </li>
                  {/* 클릭하면 버튼에 active주기 */}
                  <li>
                    <button
                      type="button"
                      id="threeday"
                      value="threeday"
                      onClick={(e: any) => {
                        handlePeriod(e);
                      }}
                      className={period == "threeday" ? `${styles.active}` : ""}
                    >
                      3일
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      id="oneweek"
                      value="oneweek"
                      onClick={(e: any) => {
                        handlePeriod(e);
                      }}
                      className={period == "oneweek" ? `${styles.active}` : ""}
                    >
                      일주일
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      id="onemonth"
                      value="onemonth"
                      onClick={(e: any) => {
                        handlePeriod(e);
                      }}
                      className={period == "onemonth" ? `${styles.active}` : ""}
                    >
                      1개월
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      id="threemonth"
                      value="threemonth"
                      onClick={(e: any) => {
                        handlePeriod(e);
                      }}
                      className={period == "threemonth" ? `${styles.active}` : ""}
                    >
                      3개월
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.button_box}>
              <button type="button" onClick={getRequestList}>
                검색하기
              </button>
            </div>
          </div>

          <div className={styles.all_top}>
            <ul>
              <li>
                총 <span>{resData.total_elements}개</span> 파일
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => {
                    downloadExcelFile(fName);
                  }}
                >
                  선택 항목 다운로드
                </button>
              </li>
            </ul>
          </div>

          <div className={styles.table_container}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={styles.table_chk}>
                    <input
                      type="checkbox"
                      className={styles.chk_option}
                      id="tb"
                      onChange={(e: any) => AllCheckHandler(e.target.checked)}
                      checked={chkList.length === resData.total_elements ? true : false}
                    />
                    <label htmlFor="tb"></label>
                  </th>
                  <th>No.</th>
                  <th>거래처명</th>
                  <th>업로드일</th>
                  <th>파일명</th>
                </tr>
              </thead>
              <tbody>
                {resData.content &&
                  resData.content.map((it: any, i) => (
                    <tr key={it.request_id}>
                      <td className={styles.table_chk}>
                        <input
                          type="checkbox"
                          className={styles.chk_option}
                          id={"tb1" + it.request_id}
                          onChange={(e: any) => checkHandler(e.target.checked, it)}
                          checked={chkList.includes(it) ? true : false}
                        />
                        <label htmlFor={"tb1" + it.request_id}></label>
                      </td>
                      <td>{i + 1}</td>
                      <td>{it.vendor_name}</td>
                      <td>{moment(it.created_date)}</td>
                      <td>{it.file_name}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`${styles.request_wrap} ${styles.clearfix}`}>
        <div className={styles.tab_box}>
          <div
            onClick={() => {
              tabActiveFunc();
            }}
            className={tab1 == 0 ? `${styles.active}` : ""}
          >
            검사 의뢰 업로드
          </div>
          <div
            onClick={() => {
              tabUnActiveFunc();
            }}
            className={tab1 == 1 ? `${styles.active}` : ""}
          >
            의뢰파일 조회
          </div>
          <div className={styles.active_tab} style={{ top: `${active}` }}></div>
        </div>
        <div>
          {/* 검사 의뢰 업로드 */}
          {tab1 == 0 ? <Upload /> : null}
          {/* 의뢰파일 조회 */}
          {tab1 == 1 ? <Check /> : null}
        </div>
      </div>
    </>
  );
}

export default Request;
