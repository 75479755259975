/* eslint-disable */
import React, { useState } from "react";
import styles from "./css/MainGX.module.css";
import Footer from "./components/Footer";

import { useDispatch } from 'react-redux';
import { authActions } from '../features/auth/authSlice';
import instance from '../common/wrapper/axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// img
import hancomLogo from "./images/HancomCarelink_CI_black.svg";
import HancomGX_Logo from "./images/common/img_HancomGX_Logo.svg";


function LoginGX({user} : any | null) {

  let userId = "";
  let userPw = "";
  let userNick = "";
  let userEmail = "";

  if(user != 'undefined' && user != null) {
    userId = user.id;
    userPw = user.password;
    userNick = user.nickname;
    userEmail = user.email;
 }

  const dispatch = useDispatch();
  
  let [id, setId] = useState("");
  let [pw, setPw] = useState("");
  

  // const navigate = useNavigate();

  // const goToMain = () => {
  //   navigate("/MainGX");
  // };

  // const stayLogin = () => {
  //   navigate("/");
  // };

  let [login_wrong, setLogin_wrong] = useState(false);

  const handleOnClick = (e: any) => {
    var v = {
    "email" : id, 
    "password" : pw, 
    "remember_me" : false
    };
    dispatch(authActions.doLogin(v));
  }

  const handleOnKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      handleOnClick(e);
    }
  }

  return (
    <>
    <section className={styles.login_back}>
      <div className={styles.login_box}>
        <img src={hancomLogo} alt="" />

        <div className={styles.login_box_wrap}>
          <img src={HancomGX_Logo} alt=""  className={styles.HancomGX_Logo}/>
        </div>

        <input onKeyDown={handleOnKeyPress} className={styles.login_id} type="email" id="id" placeholder="아이디" onChange={(e) => {
          setId(e.target.value);
        }}/>
        <input onKeyDown={handleOnKeyPress} className={styles.login_pw} type="password" id="password" placeholder="패스워드" onChange={(e) => {
          setPw(e.target.value);
        }}/>

        <div className={`${styles.block} ${styles.saveID_box}`} style={{display:"none"}}>
          {/* id 저장기능 추가해야함 */}
          <input type="checkbox" id="saveID" className={styles.saveID} defaultChecked></input>
          <label htmlFor="saveID" className={styles.saveIDLabel}></label>
          <label htmlFor="saveID" className={`${styles.saveIDLabel} ${styles.saveIDTXT}`}>아이디 저장</label>
        </div>

        {
          login_wrong == false ? null :
          <p className={styles.login_wrong}>로그인 정보를 확인해주세요 <br/>문제가 계속될 경우 관리자에게 문의 바랍니다</p>
        }
        
        <button
          className={styles.login_btn}
          type="button"
          onClick={(e) => {handleOnClick(e)}}
        >로그인</button>

      </div>
    </section>
    <Footer></Footer> 
    </>
  );
}

export default LoginGX;