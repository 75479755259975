import apiModule, { ApiRequest } from '../../common/wrapper/axios';

export interface UserEntity {
  authority: string;
  email: string;
  id: number;
  institution: string;
  institutionName: string;
  nickname: string;
  phoneNumber: string;
}

export const getUser: ApiRequest<{}, UserEntity> = () => {
  return apiModule.get('/token');
};
