/* eslint-disable */
import { useEffect, useState } from "react";
import styles from "./css/MainGX.module.css";
import Option from "./Option";
import Request from "./Request";

//img
import logout from "./images/common/icn_header_logout_24px.svg";
import headerLogo from "./images/img_HancomGX_Logo.svg";

import useLocalStorageToken from "../common/hooks/useLocalStorageToken";
import type { UserEntity } from "../features/user/userApi";

import styled from 'styled-components';

interface userApi {
  user: UserEntity | null;
}

import DownloadPopup from "./components/DownloadPopup";
import popupCheckboxActive from "./images/icn_checkbox_active.svg";
import popupCheckbox from "./images/icn_checkbox_inactive.svg";
import popup_close from './images/popup/close.svg';
import popup_img1 from './images/popup/popup1.png';

const PopupBox = styled.div`
  width: 450px;
  height: 450px;
  
  & > img {
    width: 450px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
  span {
    cursor: pointer;

    img {
      width: 24px;
      height: 35px;
      float: right;
    }
  }
  p {
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 4px;

    input[type="checkbox"] {
      & + label {
        width: 20px;
        height: 20px;
        background: url(${popupCheckbox});
        display: block;
      }
      &:checked + label {
        background: url(${popupCheckboxActive});
      }
    }
  }

  label {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: #595959;
  }

`;

function MainGX({ user }: any) {
  let isAuth = useLocalStorageToken();

  //tab
  let [tab, setTab] = useState(0);
  
  // 팝업
  let [popup1, setPopup1] = useState(0);   
  // let [popup2, setPopup2] = useState(0);  

  let [dontSeeForWeek, setDontSeeForWeek] = useState(false);

  useEffect(() => {
    const hiddenUntil = localStorage.getItem('popupHiddenUntil');
    if (hiddenUntil && new Date(hiddenUntil) > new Date()) {
      setPopup1(1);
    } else {
      setPopup1(0);
    }
  }, []);

  const handleCheckboxChange = (e: any) => {
    setDontSeeForWeek(e.target.checked);
  };

  const handleClosePopup = () => {
    if (dontSeeForWeek) {
      const now = new Date();
      const nextWeek: any = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7);
      localStorage.setItem('popupHiddenUntil', nextWeek);
    } else {
      localStorage.removeItem('popupHiddenUntil');  // remove item when the checkbox is not checked
    }
    setPopup1(1);
  };


  const Popup1 = () => {

    return (
      <>
      <PopupBox>
        <span><img src={popup_close} alt="" 
        // onClick={()=>{setPopup1(1)}}
        onClick={handleClosePopup}
        /></span>
        <img src={popup_img1} alt=""/>
        <p>
          <input type="checkbox" id="dontSeeForWeek" 
          checked={dontSeeForWeek} onChange={handleCheckboxChange}
          />
          <label htmlFor="dontSeeForWeek"></label>
          <label htmlFor="dontSeeForWeek">일주일간 보지않기</label>
        </p>     
      </PopupBox>
      </>
    )
  }
  // const Popup2 = () => {

  //   return (
  //     <>
  //     <PopupBox>
  //       <span><img src={popup_close} alt=""  onClick={()=>{setPopup2(1)}}/></span>
  //       <img src={popup_img2} alt=""/>
  //       <p>
  //         <input type="checkbox" id="dontSeeForWeek2"/>
  //         <label htmlFor="dontSeeForWeek2"></label>
  //         <label htmlFor="dontSeeForWeek2">일주일간 보지않기</label>
  //       </p>   
  //     </PopupBox>
  //     </>
  //   )
  // }

  let userId = "";
  let userPw = "";
  let userNick = "";
  let userEmail = "";
  let userInstitution = "";
  let userauthority = "";

  if (user != "undefined" && user != null) {
    userId = user.id;
    userPw = user.password;
    userNick = user.nickname;
    userEmail = user.email;
    userInstitution = user.institution_name;
    userauthority = user.authority;
  }

  const handleLogout = () => {
    sessionStorage.removeItem("auth");
    location.reload();
  };

  const params = new URLSearchParams(location.search);
  let isNew = params.get("isNew") === 'true';

  return isNew ? <DownloadPopup/> :  (
    <>
      <header className={`${styles.header} ${styles.padding}`}>
        <div className={styles.header_left}>
          {/* 로고 누르면 새로고침 되게 */}
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.location.reload();
            }}
          >
            <img src={headerLogo} alt="" className={styles.logo} />
          </div>
          <div className={styles.tab_box}>
            <div
              onClick={() => {
                setTab(0);
              }}
              className={tab == 0 ? `${styles.active}` : ""}
            >
              보고서 출력
            </div>
            <div
              onClick={() => {
                setTab(1);
              }}
              className={tab == 1 ? `${styles.active}` : ""}
            >
              의뢰 업로드
            </div>
          </div>
        </div>
        <div className={styles.header_right}>
          <div className={styles.user}>{userInstitution}</div>
          <div onClick={handleLogout}>
            <img className={styles.logout} src={logout} alt="" />
          </div>
        </div>
      </header>
      <section className={`${styles.mainGX} ${styles.padding}`}>
        {/* 보고서 출력 */}
        {tab == 0 ? <Option /> : null}
        {/* 의뢰 업로드 */}
        {tab == 1 ? <Request user={user} /> : null}
      </section>

      <div className={styles.popup}>
        {/* { popup1 == 0 ? <Popup1/> : null } */}
        {/* { popup2 == 0 ? <Popup2/> : null } */}

      </div>
      
      
    </>
  );
}

export default MainGX;
