/* eslint-disable */
import React, { useState } from "react";
import styles from "./css/MainGX.module.css";
import $ from 'jquery'


const ListGxbaby = (props: {
  table_container_height: any;
}) => {

  //info_box_wrap 높이 저장공간
  const [info_box_wrap_height, setinfo_box_wrap_height] = useState(0);
  
  const { table_container_height } = props;


  //info_box_wrap 높이 구하기
  const info_box_wrap = (element: any) => {
    if (element) {
      setinfo_box_wrap_height(element.getBoundingClientRect().height);
      // console.log(info_box_wrap_height);
    }
  }

  let table_container2_height = table_container_height - (info_box_wrap_height);
  // console.log(table_container2_height)

  //table_container2 높이 자동 조정
  const table_container2 = (element: any) => {
    if (element) {
      $("#table_container2").height(table_container2_height);
    }
  }

  return (
    <>
      <div className={styles.table_box2}>
        <p className={`${styles.table_tit1} ${styles.table_tit2}`}>수진자 정보</p>

        <div ref={info_box_wrap}>
          <div className={styles.info_box} style={{height:"184px"}}>
            <p style={{textAlign:"center", lineHeight: "140px", fontSize: "22px", color: "rgba(0,0,0,0.2)", fontWeight: "700", letterSpacing: 1.4}}>보고서를 선택해 주세요</p>
          </div>
          <div className={styles.info_table_box} style={{height: "44px"}}></div>
        </div>
        
        <div className={styles.table_container2} id="table_container2" ref={table_container2}>
          <table className={`${styles.listGx} ${styles.listGxbaby}`}>
            <thead>
              <tr>
                <th>검사코드</th>
                <th>검사명</th>
                <th>상태</th>
                <th>검사결과01</th>
                <th>검사결과02</th>
                <th>L/A/H</th>
                <th>검체명</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </>
  );
};

export default ListGxbaby;
