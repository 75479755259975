import axios from '../../common/wrapper/axios';
import localStarage from '../../common/wrapper/localStorage';

export const setAuthTokenAndHeader = (token: string) => {
  try {
    localStarage.set('auth', token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    return Promise.resolve(1);
  } catch (e) {
    return Promise.reject(null);
  }
};

export const resetAuthTokenAndHeader = () => {
  try {
    localStarage.remove('auth');
    axios.defaults.headers.common.Authorization = ``;
    return Promise.resolve(1);
  } catch (e) {
    return Promise.reject(null);
  }
};
