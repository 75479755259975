import * as ReactDOM from 'react-dom/client';
//import reportWebVitals from './reportWebVitals';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import { theme, GlobalStyle } from './common/styles';
import './index.css';

/* react18에서는 ReactDOM.render 대신 createRoot 사용 */
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(  
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <App />
    </ThemeProvider>
  </Provider>
);