/* eslint-disable */
import styles from "../css/MainGX.module.css";
import footerLogo from "../images/HancomCarelink_CI_white.svg";

const url = "http://www.hcarelink.co.kr/";

function Footer() {
  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.footer_wrap}>
          <dl>
            <dd><b>한컴케어링크</b> 사업자등록번호: 738-86-00322 | 통신판매번호 제 2022-성남분당C-0586호</dd>
            <dd>
              [13493] 경기도 성남시 분당구 대왕판교로 644번길 49 한컴타워 5층 <br />
              대표전화: 1800-5270 | FAX: 02-6949-3340
            </dd>
            <dd><b>담당자 연락처</b> Tel : 070-5102-0189 ｜ E-mail : gxsupport@hancomcl.com</dd>
            <dd>Copyright ⓒ HANCOM CARELINK</dd>
          </dl>

          <div className={styles.footer_logo_box} onClick = {() => {
            window.open(url)
          }}>
            <ul>
              <li>
                <img
                  src={footerLogo}
                  alt=""
                  className={styles.footer_logo_white}
                />
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
