/* eslint-disable */
import empty from '../images/img_main_none.png';
import styles from "../css/MainGX.module.css";

function NoResult() {
  return (
    <>
      <div className={styles.noresult}>
        <img src={empty} alt="" />
        <p>검색 옵션에 맞는 검색 결과가 없습니다.</p>
      </div>
    </>
  );
}

export default NoResult;
