import React from "react";

import { Page, Document, View, Font, StyleSheet, Text, Image } from "@react-pdf/renderer";

import type { ResultData, Report_results } from "../../../data/resultList";

//나눔스퀘어
import acB from "./fonts/NanumSquare/NanumSquare_acB.ttf";
import acEB from "./fonts/NanumSquare/NanumSquare_acEB.ttf";
import acL from "./fonts/NanumSquare/NanumSquare_acL.ttf";
import acR from "./fonts/NanumSquare/NanumSquare_acR.ttf";
import B from "./fonts/NanumSquare/NanumSquareB.ttf";
import EB from "./fonts/NanumSquare/NanumSquareEB.ttf";
import L from "./fonts/NanumSquare/NanumSquareL.ttf";
import R from "./fonts/NanumSquare/NanumSquareR.ttf";

Font.register({
  family: "NanumSquare",
  fonts: [
    {
      src: acB,
      fontWeight: 500,
    },
    {
      src: acEB,
      fontWeight: 700,
    },
    {
      src: acL,
      fontWeight: 300,
    },
    {
      src: acB,
      fontWeight: 500,
    },
    {
      src: acR,
      fontWeight: 400,
    },
    {
      src: B,
      fontWeight: "bold",
    },
    {
      src: L,
      fontWeight: "light",
    },
    {
      src: R,
      fontWeight: "normal",
    },
    {
      src: EB,
      fontWeight: "ultrabold",
    },
  ],
});


// Font.registerHyphenationCallback((word) => Array.from(word).flatMap((char) => [char, ""]));

const styles = StyleSheet.create({
  //공통
  wrap: {
    width: "100%",
    height: "100%",
    position: "relative",
    padding: "24pt 48pt",
    fontFamily: "NanumSquare",
  },
  imgTxtBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  headlogo: {
    width: "60px",
    position: "absolute",
    top: "22pt",
    right: "44pt",
    padding: "4px",
  },
  bottomTxt: {
    fontSize: "9pt",
    color: "#595959",
    position: "absolute",
    left: "48pt",
    bottom: "24pt",
    letterSpacing: -0.6,
  },
  purpleboldTxt: {
    color: "#3428BB",
    fontWeight: "bold",
  },
  boltTxt: {
    fontWeight: 500,
  },



  //1_전체결과요약
  pg2Img: {
    width: "30%",
    position: "absolute",
    top: "32px",
    right: "56px",
  },
  pg2Img_1 : {
    width: "70px",
    position: "absolute",
    top: "32px",
    left: "32px",
    padding: "2px"
  },
  product_name: {
    marginTop: "64px",
    color: "#595959",
    fontWeight: 500,
    fontSize: "16pt",
    letterSpacing: -1,
  },
  product_tit: {
    marginTop: "8px",
    fontWeight: 700,
    color: "#3428BB",
    fontSize: "20pt",
    letterSpacing: -1,
  },
  tit_back1: {
    marginTop: "20px",
    marginBottom: "-32px",
    width: "230px",
    height: "14px",
    backgroundColor: "#E3E2FF",
    letterSpacing: "0.02pt",
  },
  info_box_table: {
    marginTop: "16px",
    width: "100%",
    fontSize: "10pt",
    borderTop: "1px solid #929292",
    borderBottom: "1px solid #929292",
  },
  info_row : {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #C9C9C9",
  },
  info_row_none: {
    borderBottom: "none",
  },
  info_col: {
    padding: "6px",
    textAlign: "center",

  },
  info_col_1 : {
    width: "16%",
    backgroundColor: "#E9EFF4",
  },
  info_col_2 : {
    width: "34%",
  },
  side_box: {
    margin: "0 auto",
    width: "92%",
  },
  gh: {
    marginTop: "16px",
  },
  gh_img: {
    width: "14px",
  },
  gh_tit: {
    color: "#222",
    fontWeight: 500,
    fontSize: "11pt",
    margin: "0 0 0 6px",
    letterSpacing: -0.5,
  },
  gene_result_box: {
    position: "relative",
    overflow: "hidden",
    padding: "32px 40px 0",
    margin: "16px 0 0 0",
    width: "100%",
    backgroundColor: "#e9eff4",
    borderRadius: "16px",
    color: "#222",
  },
  g_r_t: {
    position: "absolute",
    top: "26px",
    left: "170px",
    display: "flex",
    flexDirection: "row",
    fontSize: "11pt",
    fontWeight: 600,
    color: "#222",
  },
  g_r_tR: {
    margin: "0 0 0 110px",
  },
  tatal_img: {
    position: "absolute",
    top: "32px",
    left: "40px",
    width: "110px",
  },
  rw_box: {
    margin: "6px 0 0 0",
    height: "318px",
  },
  rw_boxes: {
    padding: "0 0 0 70pt",
    margin: "10px 0 0 40px",
    backgroundColor: "#fff",
    width: "92%",
    height: "70px",
    borderRadius: "16px",
    fontSize: "10pt",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rw_fi: {
    width: "25%",
    textAlign: "center",
    fontWeight: 500,
    fontSize: "12.5pt",
  },
  rw_fi_red: {
    color: "#F8463B",
  },
  rw_fi_yellow: {
    color: "#F99C11",
  },
  rw_fi_green: {
    color: "#14AB87",
  },
  rw_sec: {
    width: "75%",
    padding: "0 5pt",
    textAlign: "center",
  },
  result_all_box: {
    margin: "16px 0 0 0",
    overflow: "hidden",
    padding: "10px",
    border: "1px solid #3428BB",
    borderRadius: "16px",
  },
  result_all: {
    color: "#222",
    textAlign: "center",
    fontSize: "10pt",
    lineHeight: 1.5,
  },
  re_txt: {
    margin: "8px 0 0 0",
    textAlign: "center",
    color: "#595959",
    fontSize: "9pt"
  },
  re_end: {
    margin: "10px 0 0 0",
    textAlign: "right",
    fontSize: "10pt",
  },
  re_bottom: {
    width: "100%",
    textAlign: "right",
    padding: "10px 0",
    borderTop: "1px solid #929292",
    fontSize: "9pt",
    color: "#595959",
    position: "absolute",
    left: "48pt",
    bottom: "24pt",
    letterSpacing: -0.6,
  },
  bottomImg: {
    position: "absolute",
    width: "72px",
    left: "48pt",
    bottom: "24pt",
    padding: "4px"
  },
  re_in: {
    marginLeft: "16px",
  },



  //항목별 결과
  dis_tit: {
    marginTop: "12px",
    display: "flex",
    flexDirection: "row",
    marginBottom: "8px",
  },
  dis_tit1: {
    fontSize: "24pt",
    fontWeight: 500,
    color: "#3428BB",
  },
  dis_tit2: {
    margin: "10px 0 0 8px",
    fontSize: "16pt",
    color: "#929292",
  },
  dis_tit_m: {
    margin: "24px 0 12px 0",
  },
  dis_tit_m_2: {
    margin: "8px 0 12px 0",
  },
  disBox: {
    justifyContent: "space-between",
    // backgroundColor: "green",
  },
  dis_left: {
    width: "49%",
    // backgroundColor: "#ccc"
  },
  dis_right: {
    width: "47%",
    // backgroundColor: "#ddd"
  },
  dis_light_box: {
    width: "100%",
    height: "84px",
    justifyContent: "space-between",
    padding: "8px",
    borderRadius: "44px",
    backgroundColor: "#E9EFF4",
  },
  dis_light_img: {
    width: "68px",
    borderRadius: "50px",
  },
  dis_right_txt1: {
    color: "#222",
    fontSize: "14pt",
    lineHeight: 1.4,
  },
  dis_right_txt1_1: {
    color: "#3428BB",
  },
  dis_right_txt1_2: {
    fontWeight: 500,
  },
  dis_right_txt2: {
    marginTop: "8px",
    fontSize: "10.5pt",
    fontWeight: 500,
    lineHeight: 1.4,
  },
  dis_right_txt2_1: {
    color: "#007B5E",
  },
  dis_right_txt2_2: {
    color: "#F99C11",
  },
  dis_right_txt2_3: {
    color: "#F8463B",
  },
  dis_wBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    padding: "16px 28px",
    border: "1px solid #929292",
    borderRadius: "16px",
    marginTop: "24px",
  },
  dis_icns: {
    width: "16px",
  },
  dis_wBox_txt: {
    color: "#222",
    fontSize: "10pt",
    margin: "10px 0 0 4px",
    lineHeight: 1.4,
  },
  dis_prevalence: {
    justifyContent: "space-between",
    color: "#222",
    fontSize: "10pt",
    // backgroundColor:"#eee"
  },
  dis_prevalence_top: {
    fontSize: "11pt",
  },
  dis_pre_h: {
    borderBottom: "1px solid #595959",
  },
  dis_pre_b: {
    fontWeight: 600,
  },
  dis_pre_width: {
    width: "105px",
    // backgroundColor: "yellow"
  },
  dis_right_txt: {
    height: "24px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dis_prevalence_bottom: {
    fontSize: "11pt",
    color: "#3428BB",
  },
  dis_right_box: {
    padding: "24px 24px 16px 24px",
    // textAlign: "justify",
    textAlign: "left",
  },
  dis_talk_img1: {
    position: "absolute",
    width: "47px",
    top: 0,
    left: 0,
  },
  dis_talk_img2: {
    position: "absolute",
    width: "47px",
    bottom: 0,
    right: 0,
  },
  dis_talk: {
    lineHeight: 1.6,
  },
  purple: {
    color: "#3428BB",
  },
  bold: {
    fontWeight: 500,
  },
  dis_blue_box: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "16px",
    width: "100%",
    overflow: "hidden",
    borderRadius: "8px",
    backgroundColor: "#E9EFF4",
    padding: "8px 40px 18px",
    fontSize: "10pt",
    color: "#222",
    fontWeight: 500,
  },
  dis_blue_box_cnt1: {
    fontSize: "11pt",
    lineHeight: 1.4,
    marginTop: "14px",
  },
  dis_blue_box_cnt2: {
    fontSize: "16pt",
    backgroundColor: "#fff",
    padding: "6px 12px",
    borderRadius: "50px",
    border: "1px dashed #3428BB",
    marginTop: "14px",
  },
  dis_blue_box_cnt3: {
    position: "relative",
  },
  dis_gradation: {
    width: "192px",
  },
  dis_result_boxes: {
    position: "relative",
    left: "20%",
    width: "65%",
    height: "24px",
    padding: "2px 0",
    // backgroundColor: "rgba(0,0,0,0.3)"
  },
  dis_align_center: {
    textAlign: "center",
  },
  dis_lowHigh: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontWeight: 400,
    marginTop: "4px",
    width: "110%",
    marginLeft: "-8px",
  },
  dis_result_bottomBox: {
    position: "absolute",
    bottom: "-11px",
  },
  dis_avg: {
    position: "absolute",
    bottom: "0px",
    left: "50%",
    transform: "translateX(-50%)",
    // backgroundColor:"rgba(0,0,0,0.3)",
  },
  dis_result_subTxt: {
    color: "#222",
    fontSize: "11pt",
    textAlign: "center",
    margin: "8px 0",
  },
  dis_col_1: {
    padding: "5px",
    width: "23%",
  },
  dis_col_2: {
    padding: "5px",
    width: "18%",
  },
  dis_col_3: {
    padding: "5px",
    width: "15%",
  },
  dis_col_4: {
    padding: "5px",
    width: "18%",
    borderRight: "1px solid #e4e4e4",
  },
  dis_col_5: {
    padding: "5px",
    width: "26%",
  },
  dis_b: {
    borderBottom: "1px solid #E4E4E4",
  },
  red: {
    color: "#F8463B",
  },

  an_gray_table: {
    borderTop: "1px soild #e4e4e4",
    borderBottom: "1px soild #e4e4e4",
    marginTop: "16px",
    width: "100%",
    overflow: "hidden",
    textAlign: "center",
    fontSize: "10pt",
  },
  gray_row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  gray_colorRow: {
    backgroundColor: "#F8F8F8",
    borderBottom: "1px soild #e4e4e4",
  },
  gray_col_1: {
    width: "25%",
    padding: "6px",
    borderRight: "1px solid #E4E4E4",
  },
  gray_col_2: {
    width: "25%",
    padding: "6px",
    borderRight: "1px solid #E4E4E4",
  },
  gray_col_3: {
    width: "25%",
    padding: "6px",
    borderRight: "1px solid #E4E4E4",
  },
  gray_col_4: {
    width: "25%",
    padding: "6px",
  },

  purple_table: {
    borderTop: "1px soild #929292",
    borderBottom: "1px soild #929292",
  },
  purple_colorRow: {
    backgroundColor: "#E3E2FF",
    borderBottom: "1px soild #E4E4E4",
  },
  purple_row_1: {
    height: "60px",
  },
  purple_col_1: {
    height: "60px",
  },
  purple_b: {
    borderTop: "1px solid #E4E4E4",
  },

  //0_표지_앞면
  coverHead_logo: {
    width: "86px",
    position: "absolute",
    top: "26pt",
    right: "28pt",
  },
  coverHead_version2: {
    width: "86px",
    position: "absolute",
    top: "60pt",
    right: "28pt",
  },
  coverHead_box: {
    width: "74.5%",
    margin: "0 auto",
    marginLeft: "48px",
    // backgroundColor: "yellow"
  },
  coverHead_Txt1: {
    marginTop: "218px",
    fontWeight: "bold",
    color: "#3B3E5A",
    fontSize: "18pt",
    letterSpacing: -0.1,
  },
  coverHead_Txt2: {
    fontWeight: "normal",
    color: "#323333",
    fontSize: "10pt",
    marginTop: "2px",
    letterSpacing: -0.1,
  },
  coverHead_Box1: {
    backgroundColor: "#E9EFF4",
    borderRadius: "12px",
    margin: "8px auto 0",
    marginLeft: "48px",
    flexDirection: "column",
    padding: "7px 0 7px 24px",
    width: "62%",
  },
  coverHead_Box2: {
    flexDirection: "row",
    height: "16px",
    alignItems: "center",
    // backgroundColor: "green",
  },
  coverHead_Box2_TxtL: {
    fontWeight: 500,
    width: "15%",
    fontSize: "9pt",
    color: "#222",
    textAlign: "left",
    // backgroundColor: "yellow"
  },
  coverHead_Box2_TxtR: {
    fontWeight: "normal",
    width: "30%",
    marginRight: "8px",
    fontSize: "9pt",
    color: "#222",
    // backgroundColor: "orange"
  },
  coverHead_Img: {
    position: "absolute",
    width: "120%",
    bottom: "40px",
  },
});

const Headlogo = ({ children, ...props }: any) => (
  <Image src="/img/report/report_hancomGX_logo_righttop.png" style={styles.headlogo} {...props}>
    {children}
  </Image>
);
const BottomTxt = ({ children, ...props }: any) => (
  <Text style={styles.bottomTxt} {...props}>
    {children}
  </Text>
);

const Br: any = () => "\n";

const reviewDate = (rday: any) => {
  const reday = rday;
  if (reday) {
    const redayArr = reday.split(" ");
    return redayArr[0];
  }
};

const DiseaseInfo = ({ chkData2 }: any) => {
  // console.log(repResult[0]);
  // const arr1: Report_results[] = repResult[0];
  // console.log("===디지즈인포=====" + chkData2);

  //------------------------------------------
  const grpToString1 = (grp: any) => {
    if (grp === 1) {
      return "보통의 관리가";
    } else if (grp === 2) {
      return "관심이";
    } else {
      return "주의가";
    }
  };

  const grpToString2 = (grp: any) => {
    if (grp === 1) {
      return "관심이";
    } else if (grp === 2) {
      return "주의가";
    } else {
      return "각별한 관리가";
    }
  };

  const genderToString = (gender: any) => {
    if (gender === "F") {
      return "여성";
    } else {
      return "남성";
    }
  };

  const korAvg = (kor: any) => {
    if (kor) {
      let kornum = kor.replace("%", "");
      kornum = Number(kornum);
      if (kornum > 1) {
        return kornum.toFixed(2);
      } else if (kornum <= 1) {
        return kornum.toFixed(3);
      }
    }
  };
  const korAvgP = (kor: any) => {
    if (kor) {
      let kornum = kor.replace("%", "");
      kornum = Number(kornum);
      if (kornum > 1) {
        let kornumc = Number(kornum.toFixed(2));
        if (kornumc < 0.1) {
          return "10만명";
        } else if (1 > kornumc && kornumc >= 0.1) {
          return "1만명";
        } else if (10 > kornumc && kornumc >= 1) {
          return "1000명";
        } else if (kornumc >= 10) {
          return "100명";
        }
      } else if (kornum <= 1) {
        let kornumc = Number(kornum.toFixed(3));
        if (kornumc < 0.1) {
          return "10만명";
        } else if (1 > kornumc && kornumc >= 0.1) {
          return "1만명";
        } else if (10 > kornumc && kornumc >= 1) {
          return "1000명";
        } else if (kornumc >= 10) {
          return "100명";
        }
      }
    }
  };

  const korAvgC = (kor: any) => {
    if (kor) {
      let kornum = kor.replace("%", "");
      kornum = Number(kornum);
      if (kornum > 1) {
        let kornumc = kornum.toFixed(2);
        if (kornumc < 0.1) {
          let kornumd = 100000 * kornumc * 0.01;
          return kornumd.toFixed(2);
        } else if (1 > kornumc && kornumc >= 0.1) {
          let kornumd = 10000 * kornumc * 0.01;
          return kornumd.toFixed(2);
        } else if (10 > kornumc && kornumc >= 1) {
          let kornumd = 1000 * kornumc * 0.01;
          return kornumd.toFixed(2);
        } else if (kornumc >= 10) {
          let kornumd = 100 * kornumc * 0.01;
          return kornumd.toFixed(2);
        }
      } else if (kornum <= 1) {
        let kornumc = kornum.toFixed(3);
        if (kornumc < 0.1) {
          let kornumd = 100000 * kornumc * 0.01;
          return kornumd.toFixed(2);
        } else if (1 > kornumc && kornumc >= 0.1) {
          let kornumd = 10000 * kornumc * 0.01;
          return kornumd.toFixed(2);
        } else if (10 > kornumc && kornumc >= 1) {
          let kornumd = 1000 * kornumc * 0.01;
          return kornumd.toFixed(2);
        } else if (kornumc >= 10) {
          let kornumd = 100 * kornumc * 0.01;
          return kornumd.toFixed(2);
        }
      }
    }
  };

  const myAvg = (koavg: any, prevalence: any) => {
    if (koavg) {
      let kornum = koavg.replace("%", "");
      kornum = Number(kornum);
      let preval = Number(prevalence);
      if (kornum > 1) {
        let kornumc = kornum.toFixed(2);
        let myAverage = kornumc * preval;
        if (myAverage > 1) {
          return myAverage.toFixed(2);
        } else if (myAverage <= 1) {
          return myAverage.toFixed(3);
        }
      } else if (kornum <= 1) {
        let kornumc = kornum.toFixed(3);
        //console.log(kornumc);
        let myAverage = kornumc * preval;
        //console.log(myAverage);
        if (myAverage > 1) {
          let mya = myAverage.toFixed(2);
          mya = mya.toString();
          //console.log(myAverage.toFixed(2));
          return mya;
        } else if (myAverage <= 1) {
          let mya = myAverage.toFixed(3);
          mya = mya.toString();
          //console.log(myAverage.toFixed(3));
          return mya;
        }
      }
    }
  };

  const myAvgC = (koavg: any, prevalence: any) => {
    if (koavg) {
      let kornum = koavg.replace("%", "");
      kornum = Number(kornum);
      let preval = Number(prevalence);
      if (kornum > 1) {
        let kornumc = kornum.toFixed(2);
        let myAverage = kornumc * preval;
        if (myAverage > 1) {
          let mya = Number(myAverage.toFixed(2));
          if (kornumc < 0.1) {
            return (mya * 100000 * 0.01).toFixed(2);
          } else if (1 > kornumc && kornumc >= 0.1) {
            return (mya * 10000 * 0.01).toFixed(2);
          } else if (10 > kornumc && kornumc >= 1) {
            return (mya * 1000 * 0.01).toFixed(2);
          } else if (kornumc >= 10) {
            return (mya * 100 * 0.01).toFixed(2);
          }
        } else if (myAverage <= 1) {
          let mya = Number(myAverage.toFixed(3));
          if (kornumc < 0.1) {
            return (mya * 100000 * 0.01).toFixed(2);
          } else if (1 > kornumc && kornumc >= 0.1) {
            return (mya * 10000 * 0.01).toFixed(2);
          } else if (10 > kornumc && kornumc >= 1) {
            return (mya * 1000 * 0.01).toFixed(2);
          } else if (kornumc >= 10) {
            return (mya * 100 * 0.01).toFixed(2);
          }
        }
      } else if (kornum <= 1) {
        let kornumc = kornum.toFixed(3);
        let myAverage = kornumc * preval;
        if (myAverage > 1) {
          let mya = Number(myAverage.toFixed(2));
          if (kornumc < 0.1) {
            return (mya * 100000 * 0.01).toFixed(2);
          } else if (1 > kornumc && kornumc >= 0.1) {
            return (mya * 10000 * 0.01).toFixed(2);
          } else if (10 > kornumc && kornumc >= 1) {
            return (mya * 1000 * 0.01).toFixed(2);
          } else if (kornumc >= 10) {
            return (mya * 100 * 0.01).toFixed(2);
          }
        } else if (myAverage <= 1) {
          let mya = Number(myAverage.toFixed(3));
          if (kornumc < 0.1) {
            return (mya * 100000 * 0.01).toFixed(2);
          } else if (1 > kornumc && kornumc >= 0.1) {
            return (mya * 10000 * 0.01).toFixed(2);
          } else if (10 > kornumc && kornumc >= 1) {
            return (mya * 1000 * 0.01).toFixed(2);
          } else if (kornumc >= 10) {
            return (mya * 100 * 0.01).toFixed(2);
          }
        }
      }
    }
  };

  const statusText = (grp: any) => {
    if (grp === 1) {
      return (
        <Text style={[styles.dis_right_txt2, styles.dis_right_txt2_1]}>
          유전적 요인 외에도 질병 유무, 식습관, 운동습관 등 <Br />
          환경적 요인들도 관여하므로 {grpToString2(grp)} 필요합니다.
        </Text>
      );
    } else if (grp === 2) {
      return (
        <Text style={[styles.dis_right_txt2, styles.dis_right_txt2_2]}>
          유전적 요인 외에도 질병 유무, 식습관, 운동습관 등 <Br />
          환경적 요인들도 관여하므로 {grpToString2(grp)} 필요합니다.
        </Text>
      );
    } else if (grp === 3) {
      return (
        <Text style={[styles.dis_right_txt2, styles.dis_right_txt2_3]}>
          유전적 요인 외에도 질병 유무, 식습관, 운동습관 등 <Br />
          환경적 요인들도 관여하므로 {grpToString2(grp)} 필요합니다.
        </Text>
      );
    }
  };

  const statusCircle = (grp: any) => {
    if (grp === 1) {
      return (
        <>
          <Image src="/img/report/report_result_status_default_1.png" style={styles.dis_light_img}></Image>
          <Image src="/img/report/report_result_status_default_2.png" style={styles.dis_light_img}></Image>
          <Image src="/img/report/report_result_status_activated_3.png" style={styles.dis_light_img}></Image>
        </>
      );
    } else if (grp === 2) {
      return (
        <>
          <Image src="/img/report/report_result_status_default_1.png" style={styles.dis_light_img}></Image>
          <Image src="/img/report/report_result_status_activated_2.png" style={styles.dis_light_img}></Image>
          <Image src="/img/report/report_result_status_default_3.png" style={styles.dis_light_img}></Image>
        </>
      );
    } else if (grp === 3) {
      return (
        <>
          <Image src="/img/report/report_result_status_activated_1.png" style={styles.dis_light_img}></Image>
          <Image src="/img/report/report_result_status_default_2.png" style={styles.dis_light_img}></Image>
          <Image src="/img/report/report_result_status_default_3.png" style={styles.dis_light_img}></Image>
        </>
      );
    }
  };

  const myType = (genotype: any, riskallele: any) => {
    let str = genotype;
    let a = str.substring(0, 1);
    let b = str.substring(1);
    let risk = riskallele;
    // console.log(str + "//" + a + "//" + b + "//" + risk + "//");
    if (a === risk && b === risk) {
      return (
        <Text>
          <Text style={styles.red}>
            {a}
            {b}
          </Text>
        </Text>
      );
    } else if (a === risk && b !== risk) {
      return (
        <Text>
          <Text style={styles.red}>{a}</Text>
          {b}
        </Text>
      );
    } else if (a !== risk && b === risk) {
      return (
        <Text>
          {a}
          <Text style={styles.red}>{b}</Text>
        </Text>
      );
    } else if (a !== risk && b !== risk) {
      return (
        <Text>
          <Text>
            {a}
            {b}
          </Text>
        </Text>
      );
    }
  };

  const chkKorean = (name: any) => {
    const lastChar = name.charCodeAt(name.length - 1);
    const isThereLastChar = (lastChar - 0xac00) % 28;
    if (isThereLastChar) {
      return "이 ";
    }
    return "가 ";
  };

  const newArray = chkData2.filter((item: { disease: any }, i: any) => {
    return (
      chkData2.findIndex((item2: { disease: any }, j: any) => {
        return item.disease === item2.disease;
      }) === i
    );
  });

  //repResult 를 arr1로 바꾸기
  const DiseaseTable = (disease: any) => {
    const findIndexArray = chkData2
      .map((item: any, index: any) => {
        if (item.disease === disease) return index;
        else return -1;
      })
      .filter((item: any) => item !== -1);

    let first = findIndexArray[0];
    let last = findIndexArray[findIndexArray.length - 1];

    const rows: any = [];
    for (let i = first; i <= last; i++) {
      rows.push(
        <View style={[styles.gray_row, styles.dis_b]} key={chkData2[i].array_id}>
          <View style={[styles.gray_col_1, styles.dis_col_1]}>
            <Text>{chkData2[i].gene}</Text>
          </View>
          <View style={[styles.gray_col_2, styles.dis_col_2]}>
            <Text>{chkData2[i].allele}</Text>
          </View>
          <View style={[styles.gray_col_3, styles.dis_col_3]}>
            <Text style={styles.red}>{chkData2[i].risk_allele}</Text>
          </View>
          <View style={[styles.gray_col_4, styles.dis_col_4]}>{myType(chkData2[i].genotype, chkData2[i].risk_allele)}</View>
          <View style={[styles.dis_col_5]}>
            <Text>{chkData2[i].risk_maker}</Text>
          </View>
        </View>
      );
      // ));
      //  }
    }
    return <>{rows}</>;
  };

  return (
    <>
      {newArray.map((it: any) => (
        <React.Fragment key={it.array_id}>
          <Page size="A4" style={styles.wrap}>
            <Headlogo />

            <View style={styles.dis_tit}>
              <Text style={styles.dis_tit1}>{it.disease}</Text>
              <Text style={styles.dis_tit2}>{it.disease_eng}</Text>
            </View>

            <View style={[styles.imgTxtBox, styles.dis_tit_m, styles.dis_tit_m_2]}>
              <Image src="/img/report/report_result_health.png" style={styles.gh_img} />
              <Text style={[styles.gh_tit]}>종합 결과</Text>
            </View>

            <View style={[styles.imgTxtBox, styles.disBox]}>
              <View style={styles.dis_left}>
                <View style={[styles.imgTxtBox, styles.dis_light_box]}>{statusCircle(it.grp)}</View>
              </View>
              <View style={styles.dis_right}>
                <Text style={styles.dis_right_txt1}>
                  유전적으로 <Text style={[styles.dis_right_txt1_1, styles.bold]}>{it.disease}</Text>에 대해 <Br />
                  <Text style={styles.dis_right_txt1_2}>{grpToString1(it.grp)}</Text> 필요한 것으로 나타납니다.
                </Text>
                {statusText(it.grp)}
              </View>
            </View>

            <View style={[styles.dis_wBox]}>
              <View style={styles.dis_left}>
                <View style={styles.imgTxtBox}>
                  <Image src="/img/report/report_result_checkUp.png" style={styles.gh_img} />
                  <Text style={[styles.gh_tit]}>확인하세요!</Text>
                </View>
                <Text style={styles.dis_wBox_txt}>{it.checkup}</Text>
              </View>
              <View style={styles.dis_right}>
                <View style={styles.imgTxtBox}>
                  <Image src="/img/report/report_result_caution.png" style={styles.gh_img} />
                  <Text style={[styles.gh_tit]}>주의하세요!</Text>
                </View>
                <Text style={styles.dis_wBox_txt}>{it.caution}</Text>
              </View>
            </View>

            <View style={[styles.imgTxtBox, styles.dis_tit_m]}>
              <Image src="/img/report/report_result_health.png" style={styles.gh_img} />
              <Text style={[styles.gh_tit]}>유전적 위험도를 반영한 고객님의 예상 유병률</Text>
            </View>

            <View style={[styles.dis_prevalence, styles.imgTxtBox]}>
              <View style={styles.dis_left}>
                <View style={[styles.dis_right_txt, styles.dis_pre_h]}>
                  <Text style={[styles.dis_pre_width]}>한국인 {genderToString(it.gender)} 평균 유병률</Text>
                  <Text style={styles.dis_prevalence_top}>
                    {korAvg(it.kor_avg)}% ({korAvgC(it.kor_avg)}명/ {korAvgP(it.kor_avg)})
                  </Text>
                </View>
                <View style={[styles.dis_right_txt, styles.dis_pre_b]}>
                  <Text style={[styles.dis_pre_width]}>고객님의 예상 유병률</Text>
                  <Text style={styles.dis_prevalence_bottom}>
                    {myAvg(it.kor_avg, it.prevalence)}% ({myAvgC(it.kor_avg, it.prevalence)}명/{korAvgP(it.kor_avg)})
                  </Text>
                </View>
              </View>
              <View style={[styles.dis_right, styles.dis_right_box]}>
                <Image src="/img/report/report_result___1.png" style={styles.dis_talk_img1}></Image>
                <Text style={styles.dis_talk}>
                  일반적으로 한국인 {korAvgP(it.kor_avg)} 당 {korAvgC(it.kor_avg)}명에서 <Text style={styles.bold}>{it.disease}</Text>
                  {/* 이/가 */}
                  {chkKorean(it.disease)}
                  발생하는 것과 비교하여, 고객님과 같은 유전형은{" "}
                  <Text style={[styles.purple, styles.bold]}>
                    {korAvgP(it.kor_avg)} 당 {myAvgC(it.kor_avg, it.prevalence)}명
                  </Text>
                  에서 <Text style={[styles.purple, styles.bold]}>{it.disease}</Text>
                  {/* 이/가 */}
                  {chkKorean(it.disease)}
                  발생할 수 있습니다.
                </Text>
                <Image src="/img/report/report_result___2.png" style={styles.dis_talk_img2}></Image>
              </View>
            </View>

            <View style={styles.dis_blue_box}>
              <Text style={[styles.dis_blue_box_cnt1, styles.dis_align_center]}>
                유전적 요인에 따른
                <Br />
                상대적 위험도
              </Text>
              <Text style={[styles.dis_blue_box_cnt2, styles.purple, styles.bold]}>{it.prevalence}배</Text>
              <View style={styles.dis_blue_box_cnt3}>
                <View style={styles.dis_result_boxes}>
                  <Text style={{ color: "#3428BB", fontWeight: 500, textAlign: "center", position: "absolute", transform: "translateX(-50%)", left: `${it.percentage}%` }}>
                    나의 위험도: {it.prevalence}배<Br />▼
                  </Text>
                </View>
                <Image src="/img/report/gradation.png" style={styles.dis_gradation}></Image>
                <View style={[styles.bold, styles.dis_lowHigh]}>
                  <Text>낮음</Text>
                  <Text>높음</Text>
                </View>
                <View style={[styles.dis_result_boxes, styles.dis_result_bottomBox]}>
                  <Text style={[styles.dis_align_center, styles.dis_avg]}>
                    ▲<Br />
                    한국인 평균 위험도 1.0
                  </Text>
                </View>
              </View>
            </View>

            <Text style={styles.dis_result_subTxt}>
              한국인의 <Text style={styles.bold}>{it.disease}</Text> 발생의 <Text style={styles.bold}>평균적인 위험도 1.0</Text>과 비교하여 고객님의{" "}
              <Text style={styles.bold}>상대적 위험도는 {it.prevalence}</Text> 입니다.
            </Text>

            <View style={[styles.an_gray_table, styles.purple_table]}>
              <View style={[styles.gray_row, styles.purple_colorRow]}>
                <View style={[styles.gray_col_1, styles.dis_col_1]}>
                  <Text>유전자명</Text>
                </View>
                <View style={[styles.gray_col_2, styles.dis_col_2]}>
                  <Text>대립인자</Text>
                </View>
                <View style={[styles.gray_col_3, styles.dis_col_3]}>
                  <Text>영향인자</Text>
                </View>
                <View style={[styles.gray_col_4, styles.dis_col_4]}>
                  <Text>나의 유전형</Text>
                </View>
                <View style={[styles.dis_col_5]}>
                  <Text>영향인자별 상대적 위험도</Text>
                </View>
              </View>
              {DiseaseTable(it.disease)}
            </View>

            <BottomTxt>본 검사결과가 갖는 임상적 의미가 확립되지 않았으며, 이에 따르는 건강에 관련한 행위가 유용하다는 객관적 타당성이 아직 부족합니다.</BottomTxt>
          </Page>

        </React.Fragment>
      ))}
    </>
  );
};

//const token = sessionStorage.getItem("auth");
interface pdfType {
  chkData: ResultData[];
  chkData2: Report_results;
  page0? : boolean;
}

const ReportDocument = ({ chkData, chkData2, page0 }: pdfType) => {

  return (
    <>
      {chkData.map((it) => (
      <Document key={it.idx}>
          {/* 0_표지_앞면 */}
          {page0 ?
              <Page size="A4" style={styles.wrap}>
                  <Image src="/img/report/logo.png" style={styles.coverHead_logo}></Image>
                  <Image src="/img/report/version2.png" style={styles.coverHead_version2}></Image>
                  <View style={styles.coverHead_box}>
                      <Text style={styles.coverHead_Txt1}>개인 유전체 분석 서비스</Text>
                      <Text style={styles.coverHead_Txt2}>Personal Genome Analysis Service</Text>
                  </View>
                  <View style={styles.coverHead_Box1}>
                      <View style={styles.coverHead_Box2}>
                          <Text style={styles.coverHead_Box2_TxtL}>의뢰기관</Text>
                          <Text style={styles.coverHead_Box2_TxtR}>{it.vendor_name}</Text>
                          <Text style={styles.coverHead_Box2_TxtL}>접수번호</Text>
                          <Text style={styles.coverHead_Box2_TxtR}>{it.barcode_no}</Text>
                      </View>
                      <View style={styles.coverHead_Box2}>
                          <Text style={styles.coverHead_Box2_TxtL}>이름</Text>
                          <Text style={styles.coverHead_Box2_TxtR}>{it.user_name}</Text>
                          <Text style={styles.coverHead_Box2_TxtL}>관리번호</Text>
                          <Text style={styles.coverHead_Box2_TxtR}>{it.chart_no}</Text>
                      </View>
                      <View style={styles.coverHead_Box2}>
                          <Text style={styles.coverHead_Box2_TxtL}>접수일</Text>
                          <Text style={styles.coverHead_Box2_TxtR}>{it.jubsu_date}</Text>
                          <Text style={styles.coverHead_Box2_TxtL}>생년월일</Text>
                          <Text style={styles.coverHead_Box2_TxtR}>{it.birth}</Text>
                      </View>
                      <View style={styles.coverHead_Box2}>
                          <Text style={styles.coverHead_Box2_TxtL}>보고일</Text>
                          <Text style={styles.coverHead_Box2_TxtR}>{reviewDate(it.send_complete_day)}</Text>
                          <Text style={styles.coverHead_Box2_TxtL}>채혈일</Text>
                          <Text style={styles.coverHead_Box2_TxtR}>{it.pick_day}</Text>
                      </View>
                  </View>
                  {/* <Image src="/img/report/report_cover_img.png" style={styles.coverHead_Img}></Image> */}
                  <Image src="/img/report/report_cover_img.png" style={styles.coverHead_Img}></Image>
              </Page>
              : <></>
          }

          {/* 1_전체결과요약 */}
          <Page size="A4" style={styles.wrap}>
            <Image src="/img/report/report_result_bg.png" style={styles.pg2Img} />
            <Image src="/img/report/report_hancomGX_logo.png" style={styles.pg2Img_1} />
            <Text style={styles.product_name}>{it.product_name}</Text>
            <View style={styles.tit_back1}></View>
            <Text style={styles.product_tit}>고객님의 유전자 분석 요약 결과</Text>

            <View style={styles.info_box_table}>
              <View style={styles.info_row}>
                <View style={[styles.info_col, styles.info_col_1]}>
                  <Text>의뢰기관</Text>
                </View>
                <View style={[styles.info_col, styles.info_col_2]}>
                  <Text>{it.vendor_name}</Text>
                </View>
                <View style={[styles.info_col, styles.info_col_1]}>
                  <Text>접수번호</Text>
                </View>
                <View style={[styles.info_col, styles.info_col_2]}>
                  <Text>{it.barcode_no}</Text>
                </View>
              </View>
              <View style={styles.info_row}>
                <View style={[styles.info_col, styles.info_col_1]}>
                  <Text>이름</Text>
                </View>
                <View style={[styles.info_col, styles.info_col_2]}>
                  <Text>{it.user_name}</Text>
                </View>
                <View style={[styles.info_col, styles.info_col_1]}>
                  <Text>관리번호</Text>
                </View>
                <View style={[styles.info_col, styles.info_col_2]}>
                  <Text>{it.chart_no}</Text>
                </View>
              </View>
              <View style={styles.info_row}>
                <View style={[styles.info_col, styles.info_col_1]}>
                  <Text>접수일</Text>
                </View>
                <View style={[styles.info_col, styles.info_col_2]}>
                  <Text>{it.jubsu_date}</Text>
                </View>
                <View style={[styles.info_col, styles.info_col_1]}>
                  <Text>생년월일</Text>
                </View>
                <View style={[styles.info_col, styles.info_col_2]}>
                  <Text>{it.birth}</Text>
                </View>
              </View>
              <View style={[styles.info_row, styles.info_row_none]}>
                <View style={[styles.info_col, styles.info_col_1]}>
                  <Text>보고일</Text>
                </View>
                <View style={[styles.info_col, styles.info_col_2]}>
                  <Text>{reviewDate(it.send_complete_day)}</Text>
                </View>
                <View style={[styles.info_col, styles.info_col_1]}>
                  <Text>채혈일</Text>
                </View>
                <View style={[styles.info_col, styles.info_col_2]}>
                  <Text>{it.pick_day}</Text>
                </View>
              </View>
            </View>

            <View style={styles.side_box}>
              <View style={[styles.gh, styles.imgTxtBox]}>
                <Image src="/img/report/report_result_health.png" style={styles.gh_img} />
                <Text style={styles.gh_tit}>나의 유전자 신호</Text>
              </View>
              <View style={styles.gene_result_box}>
                <View style={styles.g_r_t}>
                  <Text>항목수</Text>
                  <Text style={styles.g_r_tR}>항목명</Text>
                </View>
                <View style={styles.rw_box}>
                  <View style={styles.rw_boxes}>
                    <Text style={[styles.rw_fi, styles.rw_fi_red]}>{it.warning}개</Text>
                    <Text style={styles.rw_sec}>{it.warning_disease}</Text>
                  </View>
                  <View style={styles.rw_boxes}>
                    <Text style={[styles.rw_fi, styles.rw_fi_yellow]}>{it.interest}개</Text>
                    <Text style={styles.rw_sec}>{it.interest_disease}</Text>
                  </View>
                  <View style={styles.rw_boxes}>
                    <Text style={[styles.rw_fi, styles.rw_fi_green]}>{it.nomal}개</Text>
                    <Text style={styles.rw_sec}>{it.normal_disease}</Text>
                  </View>
                </View>
                <Image src="/img/report/report_tatalresult_img.png" style={styles.tatal_img} />
              </View>

              <View style={styles.result_all_box}>
                <Text style={styles.result_all}>
                  유전적 요인 분석 결과, 주의가 필요한 항목은 <Text style={{ color: "#F8463B", fontSize: "12pt", fontWeight: 500 }}>{it.warning}개</Text>, 관심이 필요한 항목은{" "}
                  <Text style={{ color: "#F99C11", fontSize: "12pt", fontWeight: 500 }}>{it.interest}개</Text>입니다.
                  <Br />
                  질병 발생은 생활습관 등의 영향도 받으므로 평소 건강한 생활습관을 가지시기 바랍니다.
                </Text>
              </View>
              <Text style={styles.re_txt}>
                본 검사결과가 갖는 임상적 의미가 확립되지 않았으며, 이에 따르는 건강에 관련한 행위가 유용하다는 객관적 타당성이 아직 부족합니다.
              </Text>
              <Text style={styles.re_end}>
                ☞ 보다 자세한 결과는 <Text style={{ color: "#3428BB" }}>‘항목별 상세 결과’</Text> 페이지에서 확인하시기 바랍니다.
              </Text>
            </View>


            <Image src="/img/report/logo.png" style={styles.bottomImg}></Image>
            <Text style={styles.re_bottom}>
              경기도 성남시 분당구 대왕판교로 644번길 49 한컴타워 5층 <Text style={styles.re_in}>Tel 1800-5270</Text>
            </Text>
          </Page>

          {/* 암 종합정보 */}
          <DiseaseInfo chkData2={chkData2} />

        </Document>
      ))}
    </>
  );
};
export default ReportDocument;
