import {Flex, Progress} from 'antd';
import styles from "../css/MainGX.module.css";
import React from "react";

const Progressbar = ({progressBar}: any) => (
    <>
        <div className={styles.progress_bar}>
          <div className={styles.bar_inner}>
            <Flex gap="large">
                <Progress
                    type="dashboard"
                    percent={progressBar}
                    size="default"
                    strokeColor='#885AF8'
                    showInfo={true}
                    strokeWidth={20}
                />
            </Flex>
            <p>다운로드 중입니다..</p>
          </div>
        </div>
    </>
);

export default Progressbar;