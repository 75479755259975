/* eslint-disable */
import React, {useEffect, useRef, useState} from "react";
import ListGxbaby from "./ListGxbaby";
import ListGxbabyNone from "./ListGxbabyNone";
import { CntForm, RequestForm } from "../data/types";
import styles from "./css/MainGX.module.css";
import axios from "../common/wrapper/axios";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import ReportDocument from "./components/report/ReportDocument";
import apiModule, { ApiRequest } from "../common/wrapper/axios";
import type { Report_results, ResultData } from "../data/resultList";
import {param} from "jquery";

const ListGx = ({ props, cnt, openModal, setOpenModal, none, setNone, active, setActive, loading, setLoading, getIDList, getReport }: any | null) => {
  const [jdata, setJdata] = useState([]);
  const intialValues = { startDate: "", userName: "", startNum: "", endDate: "", barcode: "", endNum: "", size: 100000 };

  var token = "bearer " + sessionStorage.getItem("auth");

  let [resultLst, setResultLst] = useState<RequestForm[]>([]);
  const [resCnt, setResCnt] = useState<CntForm[]>([]);

  useEffect(() => {
    const allList: any = JSON.stringify(props.content);
    const allList2: any = JSON.stringify(cnt);

    if (allList != undefined) {
      const obj = JSON.parse(allList);
      const obj2 = JSON.parse(allList2);
      setResultLst(obj);
      setResCnt(obj2);
    }
  }, [props]);

  //json data 받아오기

  //비동기 함수로 구현
  const getRequestLst = async () => {
    const req = resultLst;
    const req2 = resCnt;
    const initData: any = req.map((it: RequestForm) => {
      return {
        id: it.idx,
        idx: it.idx,
        jubsu_date: it.jubsu_date,
        chart_no: it.chart_no,
        user_name: it.user_name,
        birth: it.birth,
        gender: it.gender,
        state: it.state,
        vendor_code: it.vendor_code,
        vendor_name: it.vendor_name,
        product: it.product,
        product_name: it.product_name,
        experi_complete_day: it.experi_complete_day,
        pick_day: it.pick_day,
        memo: it.memo,
        printState: it.print_state,
        emy_yn: it.emy_yn,
        print_date: it.print_date,
        report_name: it.report_name,
        accy_code: it.accy_code,
        accy_company: it.accy_company,
      };
    });
    setResultLst(initData);
    setResCnt(req2);
  };

  //console.log(resultLst);

  useEffect(() => {
    getRequestLst();
  }, []);

  //모달 id 체크 함수
  const [modalId, setModalId] = useState(0);


  const handleSelect = (id: number) => {
    // setOpenModal(true);
    // setNone(false);
    setModalId(id);
    setLoading(true);

    apiModule
        .get(`/experiment/getResult/${id}`)
        //성공시 then 실행
        .then(function (response) {
          setJdata(response.data);
          setNone(false);
          setOpenModal(true);
          setLoading(false);
        })
        //실패 시 catch 실행
        .catch(function (error) {
          // console.log(error);
        });
  };

  const getName = (text: string, targetId: any) => {
    setResultLst(resultLst.map((it) => (it.id === targetId ? { ...it, manager: text } : it)));
  };

  // const [isAllChecked, setAllChecked] = useState(false);
  // const [checkedState, setCheckedState] = useState(new Array(resultLst.length + 1000000).fill(false));

  // const handleAllCheck = () => {
  //   setAllChecked((prev) => !prev);
  //   let array = new Array(resultLst.length + 1000000).fill(!isAllChecked);
  //   setCheckedState(array);
  // };

  // const handleMonoCheck = (position: number) => {
  //   const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));
  //   setCheckedState(updatedCheckedState);
  //   const checkedLength = updatedCheckedState.reduce((sum, currentState) => {
  //     if (currentState === true) {
  //       return sum + 1;
  //     }
  //     return sum;
  //   }, 0);
  //   setAllChecked(checkedLength === updatedCheckedState.length);
  // };

  //테이블 높이 저장공간
  const [table_container_height, setTable_container_height] = useState(0);
  //테이블 높이 구하기
  const table_container = (element: any) => {
    if (element) {
      setTable_container_height(element.getBoundingClientRect().height);
      // console.log(table_container_height);
    }
  };
  //------------------------------------------------------------------------------------------------------------혜민작업------------------------------------------------------------------------------------------
  //체크리스트 받기
  const [chkList, setChkList] = useState<number[]>([]);

  const [chkreportList, setchkReportList] = useState<ResultData[]>([]);
  const [chkrepResult, setchkRepRsult] = useState<Report_results[]>([]);

  //선택
  const checkHandler = (checked: boolean, item: number) => {
    //console.log(item);
    if (checked) {
      setChkList((prev) => [...prev, item]);
    } else if (!checked) {
      setChkList(chkList.filter((el) => el !== item));
    }
    //여기서 sendReport(); 하게되면 최종상태값 -1을 한 값이 넘어가기 때문에 이 함수 밖에서 보내야함
  };

  //전체
  const AllCheckHandler = (checked: boolean) => {
    if (checked) {
      const idArray = [] as number[];
      resultLst.forEach((el) => idArray.push(el.idx));
      setChkList(idArray);
    } else if (!checked) {
      setChkList([]);
    }
  };

  const [reportList, setReportList] = useState<ResultData[]>([]);
  const [repResult, setRepRsult] = useState<Report_results[]>([]);

  const token1 = sessionStorage.getItem("auth");

  let arr1: any[] = [];
  let arr2: any[] = [];
  const openPreview = (chkList: any) => {
    const result = Promise.all(
        chkList.map(async (param: any) => {
          return await axios
              .get(`/experiment/getResult/${param}`, {
                headers: { Authorization: "bearer " + token1 },
              })
              .then(function (response) {
                arr1.push(response.data);
                arr2.push(response.data.reposrt_results);
                //arr2.push(response.data.res)
                // console.log(response.data.reposrt_results);
              })
              .catch(function (error) {
                // console.log(error);
              });
        })
    );
    return result;
  };

  useEffect(() => {
    //console.log(chkList);
    openPreview(chkList).then((lst) => {
      setReportList(arr1);
      setRepRsult(arr2);
      //console.log(arr2);
      sendID();
    });
    // openPreview().then((result) => setReportList(result));
  }, [chkList]);

  const sendID = () => {
    getIDList(chkList);
  };

  const sendReport = () => {
    getReport(reportList, repResult);
  };

  useEffect(() => {
    sendReport();
  }, [reportList]);

  const [sortKey, setSortKey] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  let dataList = resultLst;
  const handleSort = (key: string) => {
    // Selected 초기화
    // setChkList([]);
    // setReportList([]);
    // setResultLst([]);
    // setResCnt([]);

    // sortOrder 토글
    const newSortOrder = sortKey === key && sortOrder === 'asc' ? 'desc' : 'asc';
    const dynamicSort = (key: string | number, sortOrder = 'asc') => (a: { [x: string | number] : any; }, b: { [x: string | number]: any; }) => {
      let valueA = a[key];
      let valueB = b[key];

      // 숫자인 경우 문자열로 변환하여 비교
      if (typeof valueA === 'number') {
        valueA = valueA.toString();
      }
      if (typeof valueB === 'number') {
        valueB = valueB.toString();
      }

      // 대소문자 구분 없이 문자열을 비교
      const compareResult = valueA.localeCompare(valueB, undefined, { sensitivity: 'base' });

      if (sortOrder === 'desc') {
        return compareResult * -1; // 내림차순 정렬
      }
      return compareResult; // 오름차순 정렬
    };

    let sortList;
    // 정렬 호출
    sortList = dataList.sort(dynamicSort(key, newSortOrder));
    // 소팅 키값 설정
    setSortKey(key);
    // 오름,내림차순 파라미터 설정
    setSortOrder(newSortOrder);
    let sortL = sortList;
    let sortC = resCnt;
    let initData: any = sortL.map((it: RequestForm) => {
      return {
        id: it.idx,
        idx: it.idx,
        jubsu_date: it.jubsu_date,
        chart_no: it.chart_no,
        user_name: it.user_name,
        birth: it.birth,
        gender: it.gender,
        state: it.state,
        vendor_code: it.vendor_code,
        vendor_name: it.vendor_name,
        product: it.product,
        product_name: it.product_name,
        experi_complete_day: it.experi_complete_day,
        pick_day: it.pick_day,
        memo: it.memo,
        printState: it.print_state,
        emy_yn: it.emy_yn,
        print_date: it.print_date,
        report_name: it.report_name,
        accy_code: it.accy_code,
        accy_company: it.accy_company,
        print_state : it.print_state,
      };
    });
    setResultLst(initData);
    setResCnt(sortC);
  };

  return (
      <>
        <div className={styles.tables_wrap}>
          <div className={styles.table_box1}>
            <div className={styles.table_box1_top}>
              <p className={styles.table_tit1}>수진자 리스트</p>
              <p>보류:{cnt.hold} 시작:{cnt.start} 의뢰:{cnt.request} 중간:{cnt.middle} 최종:{cnt.end}</p>

              {/* <div className={`${styles.clearfix} ${styles.btn_box}`}>
              <button type="button" onClick={openPreview}>미리보기</button>
              <button type="button">인쇄하기</button>
              <button type="button">PDF 저장</button>
              <button type="button">엑셀 변환</button>
            </div> */}
            </div>

            <div className={styles.table_container} ref={table_container}>
              <table className={styles.listGx}>
                <thead>
                <tr>
                  <th className={styles.table_chk}>
                    <input
                        type="checkbox"
                        onChange={(e: any) => AllCheckHandler(e.target.checked)}
                        className={styles.chk_option}
                        id="tb"
                        checked={chkList.length === resultLst.length ? true : false}
                    />
                    <label htmlFor="tb"></label>
                  </th>
                  <th>보고서</th>
                  <th>출력</th>
                  <th onClick={() => handleSort('emy_yn')}>응급
                    {sortKey === 'emy_yn' && sortOrder === 'asc' && '▲'}
                    {sortKey === 'emy_yn' && sortOrder === 'desc' && '▼'}
                  </th>
                  <th onClick={() => handleSort('jubsu_date')}>접수일자
                    {sortKey === 'jubsu_date' && sortOrder === 'asc' && '▲'}
                    {sortKey === 'jubsu_date' && sortOrder === 'desc' && '▼'}
                  </th>
                  <th onClick={() => handleSort('chart_no')}>등록번호
                    {sortKey === 'chart_no' && sortOrder === 'asc' && '▲'}
                    {sortKey === 'chart_no' && sortOrder === 'desc' && '▼'}
                  </th>
                  <th onClick={() => handleSort('user_name')}>수진자명
                    {sortKey === 'user_name' && sortOrder === 'asc' && '▲'}
                    {sortKey === 'user_name' && sortOrder === 'desc' && '▼'}
                  </th>
                  <th onClick={() => handleSort('birth')}>생년월일
                    {sortKey === 'birth' && sortOrder === 'asc' && '▲'}
                    {sortKey === 'birth' && sortOrder === 'desc' && '▼'}
                  </th>
                  <th onClick={() => handleSort('gender')}>성별
                    {sortKey === 'gender' && sortOrder === 'asc' && '▲'}
                    {sortKey === 'gender' && sortOrder === 'desc' && '▼'}
                  </th>
                  <th onClick={() => handleSort('state')}>상태
                    {sortKey === 'state' && sortOrder === 'asc' && '▲'}
                    {sortKey === 'state' && sortOrder === 'desc' && '▼'}
                  </th>
                  <th onClick={() => handleSort('vendor_name')}>거래처명
                    {sortKey === 'vendor_name' && sortOrder === 'asc' && '▲'}
                    {sortKey === 'vendor_name' && sortOrder === 'desc' && '▼'}
                  </th>
                  <th onClick={() => handleSort('product')}>의뢰코드
                    {sortKey === 'product' && sortOrder === 'asc' && '▲'}
                    {sortKey === 'product' && sortOrder === 'desc' && '▼'}
                  </th>
                  <th onClick={() => handleSort('product_name')}>의뢰항목명
                    {sortKey === 'product_name' && sortOrder === 'asc' && '▲'}
                    {sortKey === 'product_name' && sortOrder === 'desc' && '▼'}
                  </th>
                  <th onClick={() => handleSort('accy_code')}>부속코드
                    {sortKey === 'accy_code' && sortOrder === 'asc' && '▲'}
                    {sortKey === 'accy_code' && sortOrder === 'desc' && '▼'}
                  </th>
                  <th onClick={() => handleSort('accy_company')}>부속 거래처명
                    {sortKey === 'accy_company' && sortOrder === 'asc' && '▲'}
                    {sortKey === 'accy_company' && sortOrder === 'desc' && '▼'}
                  </th>
                  <th onClick={() => handleSort('pick_day')}>검체 채취일
                    {sortKey === 'pick_day' && sortOrder === 'asc' && '▲'}
                    {sortKey === 'pick_day' && sortOrder === 'desc' && '▼'}
                  </th>
                  <th onClick={() => handleSort('print_date')}>출력일
                    {sortKey === 'print_date' && sortOrder === 'asc' && '▲'}
                    {sortKey === 'print_date' && sortOrder === 'desc' && '▼'}
                  </th>
                  <th onClick={() => handleSort('memo')}>메모
                    {sortKey === 'memo' && sortOrder === 'asc' && '▲'}
                    {sortKey === 'memo' && sortOrder === 'desc' && '▼'}
                  </th>
                </tr>
                </thead>
                <tbody id="exList">
                {resultLst.map((it, i) => (
                    <tr
                        key={it.idx}
                        onClick={() => {
                          setActive(i);
                        }}
                        className={active == i ? `${styles.active}` : ""}
                    >
                      <td className={styles.table_chk}>
                        <input
                            type="checkbox"
                            onChange={(e: any) => checkHandler(e.target.checked, it.idx)}
                            className={styles.chk_option}
                            checked={chkList.includes(it.idx) ? true : false}
                            id={"tb" + it.idx}
                        />
                        <label htmlFor={"tb" + it.idx}></label>
                      </td>
                      <td onClick={() => handleSelect(it.idx)}>{it.report_name}</td>
                      {/* 검사: orange / 완료: blue / 대기: green */}
                      {it.print_state == "empty" ? (
                          <td className={styles.orange} onClick={() => handleSelect(it.idx)}>
                            검사
                          </td>
                      ) : it.print_state == "wait" ? (
                          <td className={styles.green} onClick={() => handleSelect(it.idx)}>
                            대기
                          </td>
                      ) : it.print_state == "done" ? (
                          <td className={styles.blue} onClick={() => handleSelect(it.idx)}>
                            완료
                          </td>
                      ) : (
                          <td onClick={() => handleSelect(it.idx)}></td>
                      )}

                      {/* 응급일 경우 styles.emer 포함 */}
                      {it.emy_yn == "Y" ? (
                          <td className={`${styles.emer} ${styles.hide_letter}`} onClick={() => handleSelect(it.idx)}>
                            {it.emy_yn}
                          </td>
                      ) : (
                          <td onClick={() => handleSelect(it.idx)}>{it.emy_yn}</td>
                      )}
                      <td onClick={() => handleSelect(it.idx)}>{it.jubsu_date}</td>
                      <td onClick={() => handleSelect(it.idx)}>{it.chart_no}</td>
                      <td onClick={() => handleSelect(it.idx)}>{it.user_name}</td>
                      <td onClick={() => handleSelect(it.idx)}>{it.birth}</td>
                      <td onClick={() => handleSelect(it.idx)}>{it.gender}</td>
                      {/* 보류: hold / 시작: start / 의뢰: request / 중간: middle / 최종: final  */}
                      {it.state == "80" ? (
                          <td className={`${styles.hold} ${styles.hide_letter} ${styles.state}`} onClick={() => handleSelect(it.idx)}>
                            {it.state}
                          </td>
                      ) : it.state == "20" ? (
                          <td className={`${styles.start} ${styles.hide_letter} ${styles.state}`} onClick={() => handleSelect(it.idx)}>
                            {it.state}
                          </td>
                      ) : it.state == "30" ? (
                          <td className={`${styles.start} ${styles.hide_letter} ${styles.state}`} onClick={() => handleSelect(it.idx)}>
                            {it.state}
                          </td>
                      ) : it.state == "40" ? (
                          <td className={`${styles.start} ${styles.hide_letter} ${styles.state}`} onClick={() => handleSelect(it.idx)}>
                            {it.state}
                          </td>
                      ) : it.state == "10" ? (
                          <td className={`${styles.request} ${styles.hide_letter} ${styles.state}`} onClick={() => handleSelect(it.idx)}>
                            {it.state}
                          </td>
                      ) : it.state == "50" ? (
                          <td className={`${styles.start} ${styles.hide_letter} ${styles.state}`} onClick={() => handleSelect(it.idx)}>
                            {it.state}
                          </td>
                      ) : it.state == "60" ? (
                          <td className={`${styles.middle} ${styles.hide_letter} ${styles.state}`} onClick={() => handleSelect(it.idx)}>
                            {it.state}
                          </td>
                      ) : it.state == "65" ? (
                          <td className={`${styles.final} ${styles.hide_letter} ${styles.state}`} onClick={() => handleSelect(it.idx)}>
                            {it.state}
                          </td>
                      ) : it.state == "70" ? (
                          <td className={`${styles.final} ${styles.hide_letter} ${styles.state}`} onClick={() => handleSelect(it.idx)}>
                            {it.state}
                          </td>
                      ) : it.state == "90" ? (
                          <td className={`${styles.final} ${styles.hide_letter} ${styles.state}`} onClick={() => handleSelect(it.idx)}>
                            {it.state}
                          </td>
                      ) : it.state == "100" ? (
                          <td className={`${styles.final} ${styles.hide_letter} ${styles.state}`} onClick={() => handleSelect(it.idx)}>
                            {it.state}
                          </td>
                      ) : (
                          <td className={` ${styles.hide_letter} ${styles.state}`} onClick={() => handleSelect(it.idx)}>
                            {it.state}
                          </td>
                      )}
                      <td onClick={() => handleSelect(it.idx)}>{it.vendor_name}</td>
                      <td onClick={() => handleSelect(it.idx)}>{it.product}</td>
                      <td onClick={() => handleSelect(it.idx)}>{it.product_name}</td>
                      <td onClick={() => handleSelect(it.idx)}>{it.accy_code}</td>
                      <td onClick={() => handleSelect(it.idx)}>{it.accy_company}</td>
                      <td onClick={() => handleSelect(it.idx)}>{it.pick_day}</td>
                      <td onClick={() => handleSelect(it.idx)}>{it.print_date}</td>
                      <td onClick={() => handleSelect(it.idx)} className={styles.memo} title={it.memo}>
                        {it.memo}
                      </td>
                    </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>

          {openModal == true ? (
              <ListGxbaby
                  open={openModal}
                  close={() => setOpenModal(false)}
                  getName={getName}
                  modalId={modalId}
                  jdata={jdata}
                  //높이 전달
                  table_container_height={table_container_height}
              />
          ) : null}

          {none == true ? <ListGxbabyNone table_container_height={table_container_height} /> : null}
        </div>
      </>
  );
};

export default ListGx;
