import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as authApi from './authApi';
import { setAuthTokenAndHeader } from './authService';

const name = 'auth';

// state
interface AuthState {
  isAuth: boolean;
  selected: string | string[];
  loading: boolean;
  error: string | undefined;
}
const initialState: AuthState = {
  isAuth: false,
  selected: '',
  loading: false,
  error: '',
};

// async
const doLogin = createAsyncThunk<number, authApi.LoginDto>(
  `${name}/doLogin`,
  async (loginDto, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authApi.doLogin(loginDto);
      return setAuthTokenAndHeader(data.access_token);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

// slice
const authSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(doLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(doLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuth = !!action.payload;
      })
      .addCase(doLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const authActions = { doLogin };
export default authSlice.reducer;
