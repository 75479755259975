import React from 'react';
import LoginGX from './GX/LoginGX';
import { BrowserRouter } from "react-router-dom";
import useLocalStorageToken from './common/hooks/useLocalStorageToken';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { getUser } from './features/user/userSlice';
import MainGX from './GX/MainGX';

function App() {
  const dispatch = useAppDispatch();
  const [isAuth] = useLocalStorageToken();
  const user = useAppSelector((state) => state.user.user);


  React.useEffect(() => {
    if (isAuth) {
       dispatch(getUser());
    }
  }, [dispatch, isAuth]);

  console.log()

  return (
    <>
     {!isAuth ? (
       <LoginGX user={user}/>
          ) : (
            <BrowserRouter>
               <MainGX user={user}/>
            </BrowserRouter>
          )}
   </>
  );
}

export default App;
