// imports
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { resetAuthTokenAndHeader } from '../../features/auth/authService';
// types
export interface ApiRequest<T, R> {
  (params?: T): Promise<AxiosResponse<R>>;
}

export type ApiRequestConfig = AxiosRequestConfig;

// functions
const instance = axios.create({
  baseURL: '/api',
});

instance.interceptors.response.use(
  (res) => {
    if (res.status === 201) {
      alert('생성 완료');
    } else if (res.status === 204) {
      alert('완료');
    } else if (res.status === 202) {
      alert(res.data.message);
    }
    return res;
  },
  (err) => {
    // TODO: 예외처리
    if ([401].includes(err.response.status)) {
      let { message } = responseValidate(err);
      if (!message) {
        message = responseValidate(err);
      }
      alert(`${message}`);
      resetAuthTokenAndHeader().then((v) => {
        if (v) {
          window.location.replace('/');
        }
      });

      return;
    } else if (err.response.status === 406) {
      return;
    } else {
      let { message } = responseValidate(err);
      if (!message) {
        message = responseValidate(err);
      }
      alert(`${message}`);
      return;
    }
  },
);

export default instance;

function blobToString(b: any) {
  var u, x;
  u = URL.createObjectURL(b);
  x = new XMLHttpRequest();
  x.open('GET', u, false); // although sync, you're not fetching over internet
  x.send();
  URL.revokeObjectURL(u);
  return x.responseText;
}

function responseValidate(error: AxiosError) {
  if (!error.response) {
    return 'network fail';
  }

  if (error.response.data) {
    if (error.response.data instanceof Blob) {
      return JSON.parse(blobToString(error.response.data));
    }
    return error.response.data;
  }
}
