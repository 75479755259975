import { Theme, Global, css } from '@emotion/react';

const letterSpacing = '-0.3px';
const lineHeight = '150%';
const bold = 600;
const normal = 400;
const fontSizeScale = [18, 16, 14, 12];

export const theme: Theme = {
  palette: {
    primary1: '#115277',
    primary2: '#68AACF',
    secondary: '#E3F4E4',
    tertiary: '#FFDC61',
    light: '#fff',
    dark: '#000',
    danger: '#E95532',
    success: '#68AACF', // primary
    grayscale: ['#C4C4C4', '#DDDDDD', '#EEEEEE', '#F6F6F6'], // darkgray, unactive, gray, lightgray
    opacityscale: [0.6, 1],
  },
  font: {
    title1: `
      font-size: ${fontSizeScale[1]}px;
      font-weight: ${bold};
      letter-spacing: ${letterSpacing};
      font-family: 'Spoqa Han Sans Neo', sans-serif;
    `,
    title2: `
      font-size: ${fontSizeScale[1]}px;
      font-weight: ${normal};
      letter-spacing: ${letterSpacing};
      font-family: 'Spoqa Han Sans Neo', sans-serif;
    `,
    subtitle: `
      font-size: ${fontSizeScale[2]}px; 
      font-weight: ${bold};
      letter-spacing: ${letterSpacing};
      font-family: 'Spoqa Han Sans Neo', sans-serif;
    `,
    body: `
      font-size: ${fontSizeScale[2]}px;
      font-weight: ${normal};
      letter-spacing: ${letterSpacing};
      line-height: ${lineHeight};
      font-family: 'Spoqa Han Sans Neo', sans-serif;
    `,
    caption: `
      font-size: ${fontSizeScale[3]}px;
      font-weight: ${normal};
      letter-spacing: ${letterSpacing};
      font-family: 'Spoqa Han Sans Neo', sans-serif;
    `,
    button: `
      font-size: ${fontSizeScale[1]}px;
      font-weight: ${bold};
      letter-spacing: ${letterSpacing};
      font-family: 'Spoqa Han Sans Neo', sans-serif;
    `,
  },
};

export const GlobalStyle = () => {
  return (
    <Global
      styles={css`
        html,
        body {
          margin: 0;
          width: 100%;
          height: 100%;
        }
        body,
        input,
        button,
        label,
        textarea {
          font-family: 'Spoqa Han Sans Neo', sans-serif;
        }
      `}
    />
  );
};
