/* eslint-disable */
import empty from '../images/img_main_empty.png';
import styles from "../css/MainGX.module.css";

function SelectResult() {
  return (
    <>
      <div className={styles.noresult}>
        <img src={empty} alt="" />
        <p>검색 옵션을 선택 후 보고서를 검색해 주세요.</p>
      </div>
    </>
  );
}

export default SelectResult;
